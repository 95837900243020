import React, { Suspense } from 'react';
import { Viewport, AppBar, AppContent, ContentContainer } from '../components/layout';
import Header from '../components/header';
import ErrorBoundary from '../components/error-boundary';
import { Helmet } from "react-helmet";
import Content from './Content'
import Footer from '../components/footer';
import Fallback from '../components/suspense-fallback';

import GoogleSiteVerification from '../components/google-site-verification';
import GoogleAnalytics from '../components/google-analytics';
import LinkedInInsight from '../components/linkedin-insight';
import HotJar from '../components/hotjar-tracking';

import SlidingModal from '../components/sliding-modal';

import './App.less';

function App() {
  return (
    <Viewport className="bccampus-viewport">
      <Helmet>
        <title>B.C. Open Collection | BCcampus</title>
        <meta
          name="description"
          content="B.C. Open Collection is a selection of assets that educators throughout the province can access for use in their classrooms, with their institution's Learning Management System (LMS)." />
        <link rel="search"
          type="application/opensearchdescription+xml"
          title="BCOC Find Materials"
          href="/open-search-description.xml"></link>
      </Helmet>
      <GoogleSiteVerification />
      <GoogleAnalytics />
      <LinkedInInsight />
      <HotJar />
      <AppBar><Header /></AppBar>
      <AppContent>
        <ErrorBoundary>
          <ContentContainer scrollable>
            <div id="content-top" />
            <Suspense fallback={<Fallback />}>
              <Content />
              <SlidingModal />
            </Suspense>
            <Footer />
          </ContentContainer>
        </ErrorBoundary>
      </AppContent>
    </Viewport>
  );
}

export default App;
