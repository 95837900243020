import { NavLink } from "react-router-dom";

import './index.css';

function TopMenu({ items, direction }) {

    const getClass = () => {
        const classNames = ['bccampus-navigation']
        classNames.push((direction === "vertical") ? 'vertical' : 'horizontal');

        return classNames.join(' ');
    };

    return (
        <nav aria-label='Site navigation' className={getClass()}>
            {items &&
                Object.entries(items).map(item => {
                    return (
                        <NavLink className="bccampus-navigation-item"
                            key={item[1].to}
                            to={item[1].to}
                            exact={item[1].exactMatch}
                        >
                            {item[1].icon}
                            {item[1].label}
                        </NavLink>
                    );
                })
            }
        </nav>
    )
}

export default TopMenu;