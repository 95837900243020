import { Helmet } from "react-helmet";

const GoogleAnalytics = () => {
  const snap = navigator.userAgent === 'ReactSnap';
  const production = process.env.NODE_ENV === 'production';
  if (!production || snap || !process.env.REACT_APP_GOOGLE_ANALYTICS_ID) return null;

  return (
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`}></script>
      <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){
                dataLayer.push(arguments);
            }
            gtag('js', new Date()); gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}');
            `}
      </script>
    </Helmet>
  )
};

export default GoogleAnalytics;
