import prerenderedLoadable from "../utils/prerendered-loadable";
import { Switch, Route } from "react-router-dom";
import NotFound from "./not-found";

const Home = prerenderedLoadable(() => import('./home'));
const About = prerenderedLoadable(() => import('./about'));
const Help = prerenderedLoadable(() => import('./help'));
const Contact = prerenderedLoadable(() => import('./forms/contact-page.js'));
const Contribute = prerenderedLoadable(() => import('./contribute'));
const SearchPage = prerenderedLoadable(() => import('./search-page'));
const Sitemap = prerenderedLoadable(() => import('./sitemap'));
const MARCExport = prerenderedLoadable(() => import('./marc-export'));
const URLResolver = prerenderedLoadable(() => import('./url-resolver'));
const ReportError = prerenderedLoadable(() => import('./forms/report-error-page.js'));
const SuggestTextbook = prerenderedLoadable(() => import('./forms/suggest-textbook-page.js'));
const ReviewTextbook = prerenderedLoadable(() => import('./forms/review-textbook-page.js'));
const EvaluateResource = prerenderedLoadable(() => import('./forms//evaluate-resource-page.js'));

function Content() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/search" component={SearchPage} />
        <Route path="/about" component={About} />
        <Route path="/help" component={Help} />
        <Route path="/contact" component={Contact} />
        <Route path="/contribute" component={Contribute} />
        <Route path="/not-found" component={NotFound} />
        <Route path="/sitemap" component={Sitemap} />
        <Route path="/marc-export" component={MARCExport} />
        <Route path="/report-error" component={ReportError} />
        <Route path="/suggest-textbook" component={SuggestTextbook} />
        <Route path="/review-textbook" component={ReviewTextbook} />
        <Route path="/evaluate-oer" component={EvaluateResource} />

        <Route path="*" component={URLResolver} />
      </Switch>
    </>
  );
}

export default Content;
