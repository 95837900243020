const formatPerson = (author, includeInstitution, withParentheses) => {
  if (!author.firstName && !author.lastName) return "Malformed Data";

  const fullname = author.firstName + " " + (author.middleName ? author.middleName + " " : "") + author.lastName;
  const institution = includeInstitution !== false && author.institution
    ? withParentheses !== true
      ? ", " + author.institution.name
      : " (" + author.institution.name + ")"
    : "";

  return fullname + institution;
};

const formatAuthor = (author, includeInstitution, withParentheses) => {
  switch (author.type) {
    case "person":
      return author.person && formatPerson(author.person, includeInstitution, withParentheses);
    case "institution":
      return author.institution ? author.institution.name : "";
    case "group":
      return author.group ? author.group.name : "";
    case "other":
      return author.other;
    default:
      return formatPerson(author, includeInstitution, withParentheses);
  }
};

const formatAuthorType = (author) => {
  switch (author.type) {
    case "person":
      return "Person";
    case "institution":
      return "Organization";
    case "group":
      return "Organization";
    default:
      return null;
  }
};

const formatImage = (image, format) => {
  if (!image) return null;

  if (image.formats && format) {
    if (format === "thumbnail")
      return (image.formats["thumbnail"] || image.formats["small"] || image.formats["medium"] || image.formats["large"] || image).url;
    if (format === "small")
      return (image.formats["small"] || image.formats["medium"] || image.formats["large"] || image).url;
    if (format === "medium")
      return (image.formats["medium"] || image.formats["large"] || image).url;
    if (format === "large")
      return (image.formats["large"] || image).url;
  }

  return image.url;
};

const formatTextbookTitle = (textbook, shortForm = false) => {
  let title = `${textbook.title}${textbook.subtitle ? `: ${textbook.subtitle}` : ""}`;

  if (shortForm !== true) {
    title += `${textbook.edition ? ` - ${textbook.edition}` : ""}${textbook.publisher ? ` (${textbook.publisher})` : ""}`;
  }

  return title;
};

export { formatAuthor, formatPerson, formatAuthorType, formatImage, formatTextbookTitle };
