import { Row, Col, Space } from 'antd';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Button from '../button';
import Image from '../image';
import Link from '../link';

import './index.css';

function PageSection({ id, children, className, expandable, style, containerStyle, contentStyle, padding, type = "default", image, actions, collapsedHeight = 330 }) {
  const [expanded, setExpanded] = useState(false);
  const sectionRef = useRef(null);

  const cssClassName = useMemo(() => {
    const classNames = className ? className.split(/\s+/) : [];
    classNames.push("bccampus-page-section");
    if (padding === "none") classNames.push('padding-none');
    else if (padding === "vertical") classNames.push('padding-vertical');
    else if (padding === "horizontal") classNames.push('padding-horizontal');

    if (expandable === true && expanded !== null) classNames.push('expandable');
    if (expanded === true) classNames.push('expanded');

    if (type === "alt" || type === "alternate") classNames.push('background-alt');
    else if (type === "dark") classNames.push('background-dark');
    else if (type === "hero") classNames.push('background-default', 'hero');
    else if (type === "hero-alt") classNames.push('background-default', 'hero-alt');
    else if (type === "hero-dark") classNames.push('background-dark', 'hero');
    else if (type === "hero-dark-alt") classNames.push('background-dark', 'hero-alt');
    else if (type === "dark-alt") classNames.push('background-dark-alt');
    else if (type === "transparent") classNames.push('background-transparent');
    else classNames.push('background-default');

    return classNames.join(' ');
  }, [className, padding, type, expandable, expanded]);

  const imageStyle = useMemo(() => {
    const _style = {};
    if (image) {
      _style[image.verticalAlign ? image.verticalAlign : "top"] = image.offset ? image.offset : "0em";
      _style[image.align ? image.align : "left"] = "0em";
      _style.objectFit = image.objectFit ? image.objectFit : "contain";
      _style[image.objectFit === "cover" ? "height" : "maxHeight"] = image.height ? image.height : "100%";
      if (image.align === "top") _style["width"] = image.width;
    }
    return _style;
  }, [image]);

  const handleExpand = useCallback(() => {
    const height = sectionRef.current.scrollHeight;
    sectionRef.current.style.height = height + "px";
    setExpanded(true);
  }, [setExpanded]);

  const handleCollapse = useCallback(() => {
    const height = sectionRef.current.scrollHeight;
    sectionRef.current.style.height = (height < collapsedHeight ? height : collapsedHeight) + "px";
    setExpanded(false);
  }, [setExpanded, collapsedHeight]);

  useLayoutEffect(() => {
    if (expandable === true) {
      let intervalExecCount = 0;
      const intervalId = setInterval(() => {
        intervalExecCount++;
        if (intervalExecCount > 100) {
          clearInterval(intervalId);
        }

        if (sectionRef.current) {
          if (sectionRef.current.scrollHeight <= (collapsedHeight + 80)) {
            setExpanded(null);
          } else {
            handleCollapse();
          }

          if (sectionRef.current.scrollHeight > 50) clearInterval(intervalId);
        }

      }, 50);
    }
  }, [expandable, handleExpand, handleCollapse, collapsedHeight]);


  return (
    <>
      <Row
        ref={sectionRef}
        id={id}
        tabIndex={-1}
        className={cssClassName}
        style={style}
      >
        <Col xs={24}>
          <div
            className={"bccampus-page-section-container " + (image?.align ?? "")}
            style={containerStyle}
          >
            <div className="bccampus-page-section-content" style={contentStyle} >
              {children}
              {Array.isArray(actions) &&
                <div>
                  <Space size={14} wrap>
                    {
                      actions.map((action) =>
                        <Link
                          key={action.id}
                          secondary={action.secondary}
                          type={action.linkStyle}
                          href={action.url}
                          image={action.image}
                          iconAlign={action.iconAlign}
                          target={action.externalTarget ? "_blank" : undefined}
                        >
                          {action.label}
                        </Link>
                      )
                    }
                  </Space>
                </div>
              }
            </div>
            {image &&
              <div className="bccampus-page-section-image"
                style={{ flexBasis: image.width ? image.width : "25%" }}
              >
                <Image
                  alt="page-section"
                  format='small'
                  data={image.file}
                  style={imageStyle}
                />
              </div>
            }
          </div>
        </Col>
      </Row>
      {
        expandable === true && expanded !== null &&
        < Row className="bccampus-page-section-footer">
          <Col xs={24} className="bccampus-page-section-actions">
            <Button type="text"
              onClick={expanded ? handleCollapse : handleExpand}>
              {expanded === false ? "Show More" : "Show Less"}
            </Button>
          </Col>
        </Row>
      }
    </>
  )
}

export default PageSection;
