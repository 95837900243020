import Link from '../link';
import BCLogo from './BCID_H_rgb_rev.png';

function BCGovBadge() {

  return (
    <div>
      <Link href="http://www2.gov.bc.ca/" aria-label="Funded by Government of British Columbia">
        <div>Funded by:</div>
        <img src={BCLogo} alt="Government of BC logo" role="presentation" style={{ width: "100%", padding: "0.5em 3em" }} />
      </Link>
    </div>
  );
}

export default BCGovBadge;
