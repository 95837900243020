import { Space } from 'antd';
import TopMenu from '../menu';
import Horizontal from '../horizontal';
import PageSection from '../page-section';

import { useRouteMatch } from 'react-router';
import useIsElementInView from '../../hooks/useIsInView';
import Link from '../../components/link';
import { BrandLogo, BrandIcon } from '../logo';
import React from 'react';

import './index.css';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

function Header() {
  const match = useRouteMatch({
    path: "/",
    strict: true,
  });

  // const isTitleInView = useIsElementInView('#page-hero h1');
  const isButtonInView = useIsElementInView('#page-hero .bccampus-link.button');

  const menuItems = {
    'about': {
      label: 'About',
      to: '/about',
      exactMatch: true,
    },
    'contribute': {
      label: 'Contribute',
      to: '/contribute',
      exactMatch: true,
    },
    'help': {
      label: 'Help',
      to: '/help',
      exactMatch: true,
    },
    'contact': {
      label: 'Contact',
      to: '/contact',
      exactMatch: true,
    },
  };

  return (
    <PageSection
      className="bccampus-site-header"
      padding="horizontal"
      type="dark"
      style={{ height: "100%", alignItems: "center" }}
    >
      <Horizontal className="large-header">
        <Horizontal.Part stretched >
          <SiteTitle />
        </Horizontal.Part>
        <Horizontal.Part centered>
          <SlidingContent visible={!match.isExact || !isButtonInView}>
            <Link type="button" href="/search/" icon={faMagnifyingGlass}>
              Find Materials
            </Link>
          </SlidingContent>
        </Horizontal.Part>
        <Horizontal.Part stretched style={{ textAlign: "right" }}>
          <TopMenu items={menuItems} />
        </Horizontal.Part>
      </Horizontal>
      <Horizontal className="small-header">
        <Horizontal.Part stretched >
          <SiteTitle logoOnly={true} />
        </Horizontal.Part>
        <Horizontal.Part style={{ textAlign: "center" }}>
          <TopMenu items={menuItems} />
        </Horizontal.Part>
        <Horizontal.Part stretched style={{ textAlign: "right" }}>
          <Link type="icon" secondary href="/search/" icon={faMagnifyingGlass} />
        </Horizontal.Part>
      </Horizontal>
    </PageSection>
  )
}

function SiteTitle({ style, logoOnly = false }) {
  return (
    <Space size="small" style={style}>
      {logoOnly
        ?
        <Link href="/" aria-label="B.C. Open Collection">
          <BrandIcon role="presentation" style={{ height: "2.25em" }} />
        </Link>
        :
        <>
          <Link href="/" header aria-label="B.C. Open Collection">
            <BrandLogo role="presentation" style={{ height: "2em" }} />
          </Link>
          <span style={{ color: "var(--primary-color-light)", fontWeight: "600", fontSize: "1.25em" }}>
            by
          </span>
          <Link header href="https://bccampus.ca/" aria-label="B.C. campus">
            BCcampus
          </Link>
        </>
      }
    </Space>
  )
}

function SlidingContent({ children, align, visible }) {

  return (
    <div
      style={{ textAlign: align, width: "100%", position: "relative" }}
      className={visible ? "slide-in" : "slide-out"}>
      {React.Children.map(children,
        child => (
          React.cloneElement(child, {
            "aria-hidden": !visible,
            tabIndex: visible ? 0 : -1
          })
        )
      )}
    </div>
  )
}

export { SiteTitle, Header };
export default Header;
