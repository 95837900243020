import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';


const userSettingsAtom = atom({});

const useUserSettings = (defaultSettings = {}) => {
  const [userSettings, setUserSettings] = useAtom(userSettingsAtom);

  useEffect(() => {
    let savedUserSession;
    try {
      savedUserSession = JSON.parse(window.localStorage.getItem("userSettings"));
    } catch {
      savedUserSession = defaultSettings;
    }

    if (savedUserSession) {
      saveUserSettings(savedUserSession);
    }
    else {
      saveUserSettings(defaultSettings);
    }
  },
    //eslint-disable-next-line
    []
  );

  const saveUserSettings = (newUserSettings) => {
    if (newUserSettings !== null && typeof newUserSettings === "object") {
      newUserSettings = { ...userSettings, ...newUserSettings };
      window.localStorage.setItem("userSettings", JSON.stringify(newUserSettings));
      setUserSettings(newUserSettings);
    }
  };

  return [userSettings, saveUserSettings];

};

export default useUserSettings;
