import { useEffect, useState } from "react";

function useIsElementInView(selector, margin) {
    const [isElementInView, setIsElementInView] = useState(false)


    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                setIsElementInView(entries[0].isIntersecting);
            },
            {
                rootMargin: margin, threshold: 0.5
            }
        );

        let intervalExecCount = 0;
        const intervalId = setInterval(() => {
            const el = document.querySelector(selector);
            intervalExecCount++;
            if (el) {
                observer.observe(el);
                clearInterval(intervalId);
            } else if (intervalExecCount > 50) {
                clearInterval(intervalId);
            }
        }, 100);

        return () => {
            observer.disconnect()
        }
    });

    return isElementInView;
}

export default useIsElementInView;
