import { Helmet } from "react-helmet";

const GoogleSiteVerification = () => {
  const snap = navigator.userAgent === 'ReactSnap';
  const production = process.env.NODE_ENV === 'production';
  if (!production || snap || !process.env.REACT_APP_GOOGLE_SITE_VERIFICATION_KEY) return null;

  return (
    <Helmet>
      <meta name="google-site-verification"
        content={process.env.REACT_APP_GOOGLE_SITE_VERIFICATION_KEY} />
    </Helmet>
  )
};

export default GoogleSiteVerification;
