import { Typography } from 'antd';
import Image from '../../image';
import Link from '../../link';


import defaultCoverImage from './default-cover-image.jpg'

import '../index.css';
import './index.css';

function CourseInfoCard({ data }) {

  return (
    <Link
      type="card" href={`/course-packs/${data.slug}/`} className="bccampus-course-info-card"
      aria-label={`${data.name} course pack`}
      aria-describedby={`bccampus-course-meta-${data.id}`}
    >
      <Image alt="cover"
        src={data.coverImage || defaultCoverImage}
        className="bccampus-course-info-card-cover"
      />
      <div className="bccampus-course-info-card-tag">
        {data.type}
      </div>
      <div className="bccampus-course-info-card-body">
        <Typography.Paragraph
          className="bccampus-card-title"
          ellipsis={{ rows: 3 }}
        >
          {data.name}
        </Typography.Paragraph>
      </div>
      <div id={`bccampus-course-meta-${data.id}`} className="bccampus-card-meta bccampus-course-info-card-meta">
        <Typography.Text ellipsis={true} style={{ width: "100%" }}>
          {data.educationalLevels?.map(level => level).join(", ")}
        </Typography.Text>
      </div>
    </Link>
  )
}

export default CourseInfoCard;
