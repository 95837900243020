import { MeiliSearch } from 'meilisearch';
import retry from 'async-retry';
import sumObjectValuesByKey from './sumObjectValuesByKey';

const searchClient = (function () {
  const client = new MeiliSearch({
    host: process.env.REACT_APP_MEILISEARCH_API_ENDPOINT,
    apiKey: process.env.REACT_APP_MEILISEARCH_PUBLIC_KEY,
  });

  const facets = ["subjects", "type", "educationalLevels"];

  const isHealthy = async () => {

    try {
      return await retry(
        async () => {
          const result = await client.isHealthy();
          // We need to throw an error to retry
          if (result === false) throw new Error("Search engine is not healthy.");

          return true;
        },
        {
          retries: 5,
          minTimeout: 500,
        }
      );
    } catch (error) {
      return false;
    }
  };


  const refreshFacets = async () => {
    const options = {
      facets,
      hitsPerPage: 0,
    };

    try {
      return await retry(
        async () => {
          const [courses, learningResources, textbooks] = await Promise.all([
            client.index('courses').search('', options),
            client.index('learning-resources').search('', options),
            client.index('textbooks').search('', options),
          ]);

          return {
            subjects: sumObjectValuesByKey(
              courses.facetDistribution?.subjects,
              learningResources.facetDistribution?.subjects,
              textbooks.facetDistribution?.subjects
            ),
            type: sumObjectValuesByKey(
              courses.facetDistribution?.type,
              learningResources.facetDistribution?.type,
              textbooks.facetDistribution?.type
            ),
            educationalLevels: sumObjectValuesByKey(
              courses.facetDistribution?.educationalLevels,
              learningResources.facetDistribution?.educationalLevels,
              textbooks.facetDistribution?.educationalLevels
            ),
          };
        },
        {
          retries: 5,
          minTimeout: 500,
        }
      );
    } catch (error) {
      return {
        subjects: {},
        type: {},
        educationalLevels: {},
      };
    }
  };

  const search = async (query, filter, settings) => {
    try {
      return await retry(
        async () => {
          const [courses, learningResources, textbooks] = await Promise.all([
            client.index('courses').search(query, {
              filter: filter,
              facets,
              hitsPerPage: settings.coursesLimit
            }),
            client.index('learning-resources').search(query, {
              filter: filter,
              facets,
              hitsPerPage: settings.learningResourcesLimit
            }),
            client.index('textbooks').search(query, {
              filter: filter,
              facets,
              hitsPerPage: settings.textbooksLimit
            }),
          ]);

          return {
            query: query,
            totalHits: courses.totalHits + learningResources.totalHits + textbooks.totalHits,
            results: {
              courses,
              learningResources,
              textbooks,
            }
          };
        },
        {
          retries: 5,
          minTimeout: 500,
        }
      );
    } catch {
      return {
        query: query,
        totalHits: 0,
        results: {
          courses: {},
          learningResources: {},
          textbooks: {},
        }
      };
    }
  };

  return {
    refreshFacets,
    search,
    isHealthy,
  }
})();

export default searchClient;
