import { Col, Row } from 'antd';
import Button from '../../button';
import CourseInfoCard from '../../cards/course-info';
import LearningResourceInfoCard from '../../cards/learning-resource-info';
import TextbookInfoCard from '../../cards/textbook-info';
import TextbookListItem from '../../list-item/list-item-textbook';
import CourseListItem from '../../list-item/list-item-course';
import LearninResourceListItem from '../../list-item/list-item-learning-resource';
import { Heading } from '../../typography';

import './index.css';

function ResultsSection(
  {
    title, results, onViewAll, cardsPerRow = { xs: 1, md: 2, xl: 4 }, onClickCard, cardType, layout = "grid"
  }
) {

  const CardComponent = (hit) => {
    if (cardType === "course")
      return <CourseInfoCard data={hit} onClick={onClickCard} />;
    if (cardType === "learning-resource")
      return <LearningResourceInfoCard data={hit} onClick={onClickCard} />;
    if (cardType === "textbook")
      return <TextbookInfoCard data={hit} onClick={onClickCard} />;

    return (
      <div>Card type is not defined!</div>
    );
  };

  const ListComponent = (hit) => {
    if (cardType === "course")
      return <CourseListItem data={hit} onClick={onClickCard} />;
    if (cardType === "learning-resource")
      return <LearninResourceListItem data={hit} onClick={onClickCard} />;
    if (cardType === "textbook")
      return <TextbookListItem data={hit} onClick={onClickCard} />;

    return (
      <div>Card type is not defined!</div>
    );
  };

  return (
    results.totalHits > 0 &&
    <div className="bccampus-search-results-section" style={{ padding: "1em 0em 2em 0em" }}>
      <Heading level={2} tabIndex="-1" aria-label={`${results.totalHits} result${results.totalHits > 1 ? "s" : ""} in the ${title} section`}>
        {title} {!!onViewAll && <span>({results.totalHits})</span>}
      </Heading>
      <Row gutter={layout === "grid" ? [24, 24] : [6, 6]}>
        {
          results.hits?.map(hit => (
            layout === "grid"
              ?
              <Col key={hit.id}
                xs={24 / cardsPerRow.xs}
                md={24 / cardsPerRow.md}
                xl={24 / cardsPerRow.xl}>
                {CardComponent(hit)}
              </Col>
              :
              <Col key={hit.id} span={24}>
                {ListComponent(hit)}
              </Col>
          ))
        }
      </Row>

      {
        !!onViewAll && results.totalHits > results.hitsPerPage &&
        <div style={{ paddingTop: "2em", textAlign: "center" }}>
          <Button secondary
            onClick={onViewAll}
            aria-label={`View ${results.totalHits - results.hitsPerPage} more ${cardType} results`}
          >
            View {results.totalHits - results.hitsPerPage} more results
          </Button>
        </div>
      }
    </div >
  );
}

export default ResultsSection;
