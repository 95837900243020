import { Helmet } from "react-helmet";
import Search from '../../components/search';
import PageSection from '../../components/page-section';

import { Heading, Paragraph, Text } from '../../components/typography';
import Link from "../../components/link";
import notFoundImage from "./searching.svg";
import { Space } from "antd";
import { Suspense } from "react";
import Fallback from "../../components/suspense-fallback";
import { useLocation } from "react-router-dom";

const reNoisyWords = /textbooks|course-packs|institutions|keywords|subjects|oer|Equivalent|authors|learning-resources|Course Number|fileformats|uploads|wp-content|feed|course|Levels|oertype|author|page|feed|ebook/gi;
const reForbiddenWords = /not found|wp includes/gi;

function NotFound() {
    const location = useLocation();
    const pathname = location.state?.from?.pathname || location.pathname;
    const parts = pathname?.split("/").map(part => part.replace(reNoisyWords, " ").replace(/[^A-Za-z0-9]/g, " ").trim().toLowerCase()).filter(part => !!part && !/^\d+$/.test(part));
    const searchQuery = (!parts || parts.length === 0 || reForbiddenWords.test(parts[0])) ? "" : parts.join(" ");

    const noSuggestionContent = (
        <div>
            <Heading level={2}>
                Looking for course materials?
            </Heading>
            <Text>
                Find course packages, textbooks, and more by <Link href="/search/">searching all materials</Link>
            </Text>
        </div>
    );

    return (
        <>
            <Helmet>
                <title>Page Not Found (404) | BCcampus</title>
            </Helmet>
            <main>
                <PageSection
                    image={{
                        verticalAlign: "bottom",
                        offset: "-2em",
                        width: "18em",
                        height: "120%",
                        file: { url: notFoundImage }
                    }}
                >
                    <div style={{ padding: "2em 0em" }}>
                        <Heading level={1}>Page Not Found</Heading>
                        <Paragraph>
                            <Text>The page you were looking for could not be found. It might have been removed, renamed, or did not exist in the first place.</Text>
                        </Paragraph>
                        <Space size="middle">
                            <Link href="/" type="button">Back Home</Link>
                            <Link href="/contact/" type="button" secondary>Contact</Link>
                        </Space>
                    </div>
                </PageSection>
                <PageSection type="alt">
                    <Suspense fallback={<Fallback title="Suggestions loading..." />}>

                        {!!searchQuery
                            ?
                            <Search
                                query={searchQuery}
                                showSearchBox={false}
                                showFilters={[]}
                                showTitle={false}
                                showFooter={false}
                                showDescription={true}
                                descriptionContent={
                                    <div>
                                        <Heading level={2}>
                                            Looking for these?
                                        </Heading>
                                        <Text>
                                            Here are some course materials you might be interested in or you can <Link href="/search/">search all materials</Link>
                                        </Text>
                                    </div>
                                }
                                viewAllCourses={false}
                                viewAllLearningResources={false}
                                noResultContent={noSuggestionContent}
                            />
                            :
                            noSuggestionContent
                        }
                    </Suspense>
                </PageSection>
            </main>
        </>
    );
}

export default NotFound;
