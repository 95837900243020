import { faMagnifyingGlass, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Space } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import debounce from 'lodash.debounce';
import { useEffect, useRef, useState, useLayoutEffect } from 'react';
import Button from '../button';

import './index.css'

function SearchBox({ style, value, onChange }) {
  const [query, setQuery] = useState(value);
  const screens = useBreakpoint();
  const inputRef = useRef(null);

  const handleChange = (e) => {
    const newQuery = e.target.value;
    changeQuery(newQuery);
  }

  const changeQuery = (query) => {
    setQuery(query);
    onChange(query);
  }

  const handleEnter = debounce(() => {
    const firstResult = document.querySelector('.bccampus-link.card');
    const header = document.querySelector('#bccampus-search-hits-container h2');
    if (firstResult) {
      inputRef.current.blur();
      if (header) {
        header.scrollIntoView(true);
        header.focus({ preventScroll: true });
      }
    }
  }, 500);

  useEffect(() => {
    setQuery(value);
  }, [value]);

  useLayoutEffect(() => {
    if (screens.xl) inputRef.current.focus({ cursor: 'end' });
  }, [screens]);

  return (
    <Space className="bccampus-search-bar" align="center" style={style}>
      <Input
        ref={inputRef}
        value={query}
        onChange={handleChange}
        onPressEnter={handleEnter}
        placeholder="What are you teaching?"
        suffix={!!query.trim()
          ?
          <Button
            type="icon" icon={faTimes} aria-label="Clear search"
            style={{ width: "1.5em", height: "1.5em", fontSize: "1em" }}
            onClick={() => changeQuery("")}
          />
          :
          <></>
        }
        aria-label="Search"
        style={{ width: "100%" }}
        aria-controls="bccampus-search-result-notice"
      />
      <Button onClick={handleEnter} aria-label="Show search results"><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
    </Space>
  )
}

export default SearchBox;
