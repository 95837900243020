import { Helmet } from "react-helmet";

const LinkedInInsight = () => {
  const snap = navigator.userAgent === 'ReactSnap';
  const production = process.env.NODE_ENV === 'production';
  if (!production || snap || !process.env.REACT_APP_LINKEDIN_INSIGHT_TAG) return null;

  return (
    <Helmet>
      <script type="text/javascript" async src="https://snap.licdn.com/li.lms-analytics/insight.min.js"></script>
      <script type="text/javascript">
        {`
        _linkedin_partner_id = "${process.env.REACT_APP_LINKEDIN_INSIGHT_TAG}";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
      `}
      </script>
      <noscript>
        {`
        <img height="1" width="1" style="display:none;" alt=""
        src="https://px.ads.linkedin.com/collect/?pid=${process.env.REACT_APP_LINKEDIN_INSIGHT_TAG}&fmt=gif" />
      `}
      </noscript>
    </Helmet>
  )
};

export default LinkedInInsight;
