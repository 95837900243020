import { Typography } from 'antd';
import defaultCoverImage from './default-cover-image.jpg'
import Link from '../../link';
import Image from '../../image';

import '../index.css';
import './index.css';

function LearningResourceInfoCard({ data }) {

  return (
    <Link
      type="card" className="bccampus-material-info-card" href={`/learning-resources/${data.slug}/`}
      aria-label={`${data.type}: ${data.title}${data.course ? ` for ${data.course}` : ""}`}
    >
      <Image alt="cover"
        src={data.coverImage || defaultCoverImage}
        className="bccampus-material-info-card-cover"
      />
      <div className="bccampus-material-info-card-tag">
        {data.type}
      </div>
      <div className="bccampus-material-info-card-body">
        <Typography.Paragraph
          className="bccampus-card-title"
          ellipsis={{ rows: 3 }}
          title={data.title}
        >
          {data.title}
        </Typography.Paragraph>
        <Typography.Paragraph
          className="bccampus-card-extra"
          ellipsis={{ rows: 2 }}
          style={{ margin: "0em", width: "100%" }}
          title={data.course}
        >
          For {data.course}
        </Typography.Paragraph>
      </div>
    </Link>
  )
}

export default LearningResourceInfoCard;
