import apiRequest from '../../utils/bccampusGraphQLRequest';
import { GetSubjectsQuery, GetSubjectHierarchyQuery, GetSubjectByIdQuery, GetSubjectBySlugQuery } from '../queries/Subjects.graphql';

const read = async (id) => {
  if (id) {
    return await getById(id);
  } else {
    return await getAll();
  }
}

const getById = async (id) => {
  const variables = {
    id: id,
  };
  const result = await apiRequest(GetSubjectByIdQuery, variables);
  return result.subject;
}

const getAll = async () => {
  const result = await apiRequest(GetSubjectsQuery);
  return result.subjects;
}

const readBySlug = async (slug) => {
  const variables = {
    slug: slug,
  };
  const result = await apiRequest(GetSubjectBySlugQuery, variables);
  return result.subjects;
}


const getHierarchy = async () => {
  const result = await apiRequest(GetSubjectHierarchyQuery);
  return result.subjects;
}

const subjectsRepository = { read, readBySlug, getAll, getHierarchy };

export { read, getHierarchy, getAll }
export default subjectsRepository
