import Link from '../link';
import { ReactComponent as BCcampusLogo } from './bccampus-logo.svg';

function BCcampusBadge() {

  return (
    <div>
      <Link href="http://www.bccampus.ca/" aria-label="Designed and developed by BCcampus">
        <div>Designed and developed by:</div>
        <BCcampusLogo className="bccampus-logo" alt="bccampus logo" role="presentation" style={{ width: "100%", padding: "0.5em 3em" }} />
      </Link>
    </div>
  );
}

export default BCcampusBadge;
