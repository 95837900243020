import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Label, Text } from "./typography";

const Fallback = ({ title, content, icon = faRotateRight, iconSize = "5x", iconSpin = true, spinDuration = "3s" }) => (
  <>
    <div></div>
    <div style={{
      justifySelf: "center",
      textAlign: "center",
      display: "flex",
      gap: "1em",
      alignContent: "center",
      justifyContent: "center",
      flexFlow: "column",
      padding: "2em",
    }}>
      <FontAwesomeIcon icon={icon} spin={iconSpin} size={iconSize} style={{ "--fa-animation-duration": spinDuration }} />
      <Label>
        {title ?? "Content loading..."}
      </Label>
      {content && <Text>{content}</Text>}
    </div>
  </>
);

export default Fallback;
