
import { useMemo } from 'react';
import { formatImage } from '../../utils/formatFields';
import { ReactSVG } from 'react-svg';

import './index.css';

/**
 * Formats: original | thumbnail | small | medium | large
 */
const Image = ({ src, alt, data, format, bordered, rounded, className, icon, size, ...restProps }) => {

  const classNames = useMemo(() => {
    const classNames = ['bccampus-img'];
    if (className) classNames.push(className);
    if (bordered === true) classNames.push('bordered');
    if (rounded === true) classNames.push('rounded');
    if (icon === true) classNames.push('icon');
    if (size) classNames.push(`size-${size}`);

    return classNames.join(' ');
  }, [bordered, rounded, className, icon, size]);

  if (!data && !src) return null;

  const commonProps = {
    ...restProps,
    src: formatImage(data, format) || src,
    className: classNames,
    role: "presentation",
  }
  const alternativeText = data?.alternativeText || alt;

  return (
    data?.mime === "image/svg+xml"
      ? <ReactSVG {...commonProps} desc={alternativeText} />
      : <img {...commonProps} alt={alternativeText} />
  );
};

export default Image;
