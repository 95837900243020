import { Typography } from 'antd';
import { useMemo } from 'react';
import Button from '../../button';
import Link from '../../link';
import Image from '../../image';

import './index.css';

function FilterBox({ id, label, extra, color, image, style, onClick, href, ...restProps }) {
  const _color = color || "#445566";

  const classNames = useMemo(() => {
    const classNames = ['bccampus-search-filter-box', label.trim().toLowerCase().replace(/[^a-z0-9]+/g, '-')]
    return classNames.join(' ');
  }, [label]);

  const styles = useMemo(() => ({
    border: `2px solid ${_color}`,
    color: _color,
  }), [_color]);

  return (
    !!onClick
      ?
      <Button type="card" id={id} className={classNames}
        style={{ ...style, backgroundColor: "transparent", color: styles.color, border: styles.border }}
        onClick={onClick}
        {...restProps}
      >
        <div className="bccampus-search-filter-box-icon">
          <Image data={image} style={{ fill: styles.color }} alt={`${label} icon`} />
        </div>

        <Typography.Text className="bccampus-search-filter-box-label" style={{ color: styles.color }}>
          {label}
        </Typography.Text>

        <div className="bccampus-search-filter-box-extra" style={{ color: styles.color }}>
          {extra}
        </div>
      </Button>
      :
      <Link type="card" id={id} className={classNames}
        style={{ ...style, backgroundColor: "transparent", color: styles.color, border: styles.border }}
        href={href}
        {...restProps}
      >
        <div className="bccampus-search-filter-box-icon">
          <Image data={image} style={{ fill: styles.color }} alt={`${label} icon`} />
        </div>
        <Typography.Text className="bccampus-search-filter-box-label" style={{ color: styles.color }}>
          {label}
        </Typography.Text>

        <div className="bccampus-search-filter-box-extra" style={{ color: styles.color }}>
          {extra}
        </div>
      </Link>
  )
}

export default FilterBox;
